<template>
  <div id="body">
    <div class="header">
      <div>
        <p style="color: #ff5a21">{{parentNum}}</p>
        <p>家长总数量</p>
      </div>
      <div>
        <p style="color: #3aa0ff">{{stuNum}}</p>
        <p>学生总数量</p>
      </div>
      <div>
        <p style="color: #1ac574">{{testNum}}</p>
        <p>总检测次数</p>
      </div>
      <div>
        <p style="color: #30cadc">{{testPeople}}</p>
        <p>总检测人数</p>
      </div>
      <div>
        <p style="color: #ff5a21">{{todayParentNum}}</p>
        <p>今日新增家长总数量</p>
      </div>
      <div>
        <p style="color: #3aa0ff">{{todayStuNum}}</p>
        <p>今日新增学生总数量</p>
      </div>
      <div>
        <p style="color: #1ac574">{{todayTestNum}}</p>
        <p>今日检测总次数</p>
      </div>
      <div>
        <p style="color: #30cadc">{{todayTestPeople}}</p>
        <p>今日检测总人数</p>
      </div>
    </div>
    <div class="ClassInfo_box">
      <div class="chartTitle">
        <p class="facility">学校数据统计</p>
      </div>
      <el-table :data="data" style="width: 100%" v-loading="loading">
        <el-table-column prop="ID" label="学校ID" align="center" width="100"></el-table-column>
        <el-table-column prop="SchoolName" label="学校名称" align="center"></el-table-column>
        <el-table-column prop="InsertTime" label="注册时间	" align="center"></el-table-column>
        <el-table-column prop="parentNum" label="家长总数" align="center"></el-table-column>
        <el-table-column prop="stuNum" label="学生总数	" align="center"></el-table-column>
        <el-table-column prop="schoolCountTestNum" label="总检测次数" align="center"></el-table-column>
        <el-table-column prop="schoolCountTestPeople" label="总检测人数	" align="center"></el-table-column>
        <el-table-column prop="schoolTodayCountTestNum " label="今日检测次数" align="center"></el-table-column>
        <el-table-column prop="schoolTodayCountTestPeople" label="今日检测人数" align="center"></el-table-column>
      </el-table>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    schoolData,
    testData
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
        parentNum: '', // 家长总数量
        stuNum: '', // 学生总数量
        testNum: '', // 总检测次数
        testPeople: '', // 总检测人数
        todayParentNum: '', // 今日新增家长总数量
        todayStuNum: '', // 今日新增学生总数量
        todayTestNum: '', // 	今日检测总次数
        todayTestPeople: '', // 今日检测总人数
        data: [],
        loading: false, //加载框
        currpage: 1, // 初始页
        pagesize: 12, // 每页显示条目个数
        CountNum: 0, // 总条数
      }
    },
    mounted() {
      this.testData() // 获取头部信息数据
      this.schoolData(1) // 默认今日数据
    },
    methods: {
      testData() { // 头部设备数据
        testData().then(res => {
          this.parentNum = res.data.parentNum // 家长总数量
          this.stuNum = res.data.stuNum // 学生总数量
          this.testNum = res.data.testNum // 总检测次数
          this.testPeople = res.data.testPeople // 总检测人数
          this.todayParentNum = res.data.todayParentNum // 今日新增家长总数量
          this.todayStuNum = res.data.todayStuNum // 今日新增学生总数量
          this.todayTestNum = res.data.todayTestNum // 	今日检测总次数
          this.todayTestPeople = res.data.todayTestPeople // 今日检测总人数
        }).catch(err => {
          console.log(err)
        })
      },
      schoolData(currpage) { // 学校列表
        this.loading = true
        const data = {
          page: currpage, // 当前页数
          limit: this.pagesize // 每页条数
        }
        schoolData(data).then(res => {
          this.data = res.data.List // 学校列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) { // 分页
        this.schoolData(val)
        this.currpage = val
      }
    }
  }
</script>

<style lang="scss" scoped>
  #body {
    min-height: 94%;
    overflow: hidden;
  }

  .header {
    display: flex;
    height: 70px;
    justify-content: space-around;

    div {
      p:nth-child(1) {
        font-size: 20px;
        font-weight: normal;
      }

      p:nth-child(2) {
        font-size: 14px;
        font-weight: normal;
        color: #000000;
      }
    }
  }

  .ClassInfo_box {
    width: 96%;
    margin: 0 auto;
    background: white;
    margin-top: 25px;

    .chartTitle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #cccccc;

      .facility {
        font-size: 16px;
        line-height: 24px;
        color: #1890FF;
        margin-left: 5px;
        font-family: PingFangSC-Medium;
      }
    }
  }
</style>
